import request from "../utils/request"
const baseURL = '/opt/'
// 登陆
export function login(params) {
  return request ({
    url: '/system/login/wechat',
    method: 'post',
    data: params
  })
}
//个人报销申请页面
// 基础信息查询
export function reimburseBaseInfo() {
  return request ({
    url: `${baseURL}exp/reimburse/baseInfo`,
    method: 'post',
  })
}
// 客户列表查询
export function reimburseCustomerList() {
  return request ({
    url: `${baseURL}exp/reimburse/customerList`,
    method: 'post',
  })
}
// 部门列表查询
export function reimburseDepartmentList(params) {
  return request ({
    url: `${baseURL}accounts/departmentList`,
    method: 'post',
    data: params
  })
}
// 公司列表查询
export function companyList(params) {
  return request ({
    url: `${baseURL}contract/companyList`,
    method: 'post',
    data: params
  })
}

// 费用类型列表查询
export function reimburseFeeTypeList() {
  return request ({
    url: `${baseURL}exp/reimburse/feeTypeList`,
    method: 'post',
  })
}
// 获取发票链接地址
export function reimburseGetUrl(params) {
  return request ({
    url: `${baseURL}exp/reimburse/getUrl`,
    method: 'post',
    data: params
  })
}
// 报销单提交  编辑报销单提交
export function reimburseSubmit(params) {
  return request ({
    url: `${baseURL}exp/reimburse/submit`,
    method: 'post',
    data: params
  })
}
//个人报销历史记录页面
//报销单列表分页查询
export function reimbursePageQuery(params) {
  return request ({
    url: `${baseURL}exp/reimburse/pageQueryWorkflow`,
    method: 'post',
    data: params
  })
}
//报销单列表分页查询
export function reimburseDetail(params) {
  return request ({
    url: `${baseURL}exp/reimburse/detail`,
    method: 'post',
    data: params
  })
}
export function identifyContent(params) {
  return request ({
    url: `${baseURL}accounts/identifyContent`,
    method: 'post',
    data: params
  })
}
export function getAccounts(params) {
  return request ({
    url: `${baseURL}accounts/getAccounts`,
    method: 'post',
    data: params
  })
}
export function confirmCash(params) {
  return request ({
    url: `${baseURL}accounts/confirmCash`,
    method: 'post',
    data: params
  })
}
//合同列表
export function contractList(params) {
  return request ({
    url: `${baseURL}contract/pageQueryWorkflow`,
    method: 'post',
    data: params
  })
}
// 审批同意
export function auditPass(params) {
  return request ({
    url: `${baseURL}workflow/auditPass`,
    method: 'post',
    data: params
  })
}
// 审批驳回
export function auditReject(params) {
  return request ({
    url: `${baseURL}workflow/auditReject`,
    method: 'post',
    data: params
  })
}
// 合同详情
export function contractView(params) {
  return request ({
    url: `${baseURL}contract/contractView`,
    method: 'post',
    data: params
  })
}
// 报销
export function billProcess(params) {
  return request ({
    url: `${baseURL}workflow/billProcess`,
    method: 'post',
    data: params
  })
}

//审批人自选列表
export function getAllPerson(params){
  return request ({
    url:`${baseURL}customer/getAllPerson`,
    method:'post',
    data:params
  })
}

//转审
export function referral(params){
  return request({
    url:`${baseURL}workflow/referral`,
    method:'post',
    data:params
  })
}

//我的审批分页查询
export function rementWorkflow(params){
  return request({
    url:`${baseURL}cashRequirement/pageQueryWorkflow`,
    method:'post',
    data:params
  })
}

//走款
export function expenseType(params){
  return request({
    url:`${baseURL}cashRequirement/expenseTypeList`,
    method:'post',
    data:params
  })
}

//详情 
export function cashdetail(params){
  return request({
    url:`${baseURL}cashRequirement/detail`,
    method:'post',
    data:params
  })
}

//详情 
export function reqContractBalance(params){
  return request({
    url:`${baseURL}cashRequirement/getContractBalance`,
    method:'post',
    data:params
  })
}
//返费详情 
export function reqRefundFeeDetail(params){
  return request({
    url:`${baseURL}cashRequirement/refundFeeDetail`,
    method:'post',
    data:params
  })
}

//二类户详情
export function cashRequirementdetail(params){
  return request({
    url:`${baseURL}cashRequirement/professionalDetail`,
    method:'post',
    data:params
  })
}

//二类户提交
export function cashRequirementSubmit(params){
  return request({
    url:`${baseURL}cashRequirement/professionalSubmit`,
    method:'post',
    data:params
  })
}

//个人出差查询
export function getBusinessTravel(params){
  return request({
    url:`${baseURL}exp/reimburse/getBusinessTravel`,
    method:'post',
    data:params
  })
}

// 发票审批查询
export function getInvoicePageQuery(params){
  return request({
    url:`${baseURL}invoice/pageQueryWorkflow`,
    method:'post',
    data:params
  })
}

// 发票申请详情
export function getInvoiceDetail(params){
  return request({
    url:`${baseURL}invoice/application/detail`,
    method:'post',
    data:params
  })
}

// 查询合同列表
export function getContractList(params){
  return request({
    url:`${baseURL}contract/listContractByType`,
    method:'post',
    data:params
  })
}

// 其他审批列表
export function getOtherApprovalList(params){
  return request({
    url:`${baseURL}product/pageQueryWorkflow`,
    method:'post',
    data:params
  })
}

// 产品详情
export function getProductDetail(params){
  return request({
    url:`${baseURL}product/detail`,
    method:'post',
    data:params
  })
}

// 客户信息查询
export function reqCustomerInfo(params){
  return request({
    url:`${baseURL}contractEvaluation/customerInfo`,
    method:'post',
    data:params
  })
}

// 获取业务关联客服
export function reqRelatedCustService(params){
  return request({
    url:`${baseURL}contractEvaluation/relatedCustService`,
    method:'post',
    data:params
  })
}

// 根据渠道id获取渠道名称和合同名称
export function reqChannelInfo(params){
  return request({
    url:`${baseURL}contractEvaluation/getChannelInfo`,
    method:'post',
    data:params
  })
}

// 公司申请列表
export function reqCompanyList(params) {
  return request ({
    url: `${baseURL}companyEstablish/pageQueryWorkflow`,
    method: 'post',
    data: params
  })
}
// 公司申请详情
export function reqCompanyDetail(params) {
  return request ({
    url: `${baseURL}companyEstablish/detail`,
    method: 'post',
    data: params
  })
}